
import { defineComponent } from "vue";
import OTP from "@/views/authentication/auth/Otp.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "trainee-signup",
  components: {
    Form,
    Field,
    ErrorMessage,
    OTP
  },
  data() {
    return {
      
      trainee: {
        name: "",
        mobile: "",
        email: "",
      },
     
      contact_person:{},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      loading: false,
      showentityInstitute: false,
      showOtpForm: false,
      showTraineeForm: true,
      otp:'',
      trainee_name:'',
      trainee_mobile:'',
      trainee_email:','
    };
  },
  async created() {

  },
  methods: {
    async formSubmit() {
      //let data = new FormData();
      this.loading = true;
      let formData = new FormData();

      for (var key in this.trainee) {
        formData.set(key, this.trainee[key]);
      }
      await ApiService.post("trainee/verify_email", formData)
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
             if(response.data.status == 'error'){
              this.loading = false;
                Swal.fire({
                  title: "Error!",
                  html: response.data.message,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Close",
                  customClass: {
                    confirmButton: "btn btn-danger",
                  },
              });
            }else{
              Swal.fire({
                title: "Success!",
                text: "OTP successfully sent to your email.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              }).then(() => {
                this.showOtpForm = true;
                this.showTraineeForm = false;
                this.otp = response.data.data.otp;
                this.trainee_name = response.data.data.name;
                this.trainee_mobile = response.data.data.mobile;
                this.trainee_email = response.data.data.email;
                this.otp = response.data.data.otp;
                  
              });
            }


          } else {
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Please check all the required field",
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: "Unknown error",
            html: response.data.error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
          console.log(response);
        });

    },


  },
  setup() {
    const AssociationSchema = Yup.object().shape({
      email: Yup.string()
        .email("Must be valid email")
        .required()
        .label("Email"),
     
      name: Yup.string().required().label("Name"),
      mobile: Yup.number().required().min(11).label("Mobile"),
    });
    return {
      AssociationSchema,
    };
  },
});
